/**
 * @name: 小程序管理-推广页管理接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-推广页管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import {IAppletPromote} from "@/apis/applet/promote/types";

/**
 * 分页查询
 * @param params
 */
export const promotionQueryApi = (params: IPage) => get<IPageRes<IAppletPromote[]>>("/card/promotion/query", params)
/**
 * 创建
 * @param data
 */
export const promotionCreateApi = (data: Partial<IAppletPromote>) => postJ("/card/promotion/create", data)
/**
 * 编辑
 * @param data
 */
export const promotionModifyApi = (data: Partial<IAppletPromote>) => postJ("/card/promotion/modify", data)
/**
 * 详情
 * @param id
 */
export const promotionDetailApi = (id: string) => get<IAppletPromote>("/card/promotion/detail/" + id)
