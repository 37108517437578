
/**
 * @name: 小程序管理-推广页管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 小程序管理-推广页管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {promotionCreateApi, promotionDetailApi, promotionModifyApi, promotionQueryApi} from "@/apis/applet/promote";
import {IAppletPromote} from "@/apis/applet/promote/types";

@Component({})
export default class appletPromote extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    delBtn: false,
    dialogWidth: '600px',
    column: [
      {
        label: "序号",
        type: "index",
        width: 50,
        align: "center",
        addHide: true,
        editHide: true
      },
      {
        label: "图片",
        prop: "pic",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: "图片不能为空", trigger: "blur"},
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        type: "number",
        rules: [
          {required: true, message: "排序不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('排序不能为空和0'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('排序必须为正整数'))
              }
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大值99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "跳转商品",
        prop: "productName",
        align: "center",
        addHide: true,
        editHide: true,
        overHidden: true
      },
      {
        label: "跳转商品",
        prop: "productId",
        align: "center",
        hide: true,
        rules: [
          {required: true, message: "跳转商品不能为空", trigger: "blur"}
        ],
        // @ts-ignore
        filterable: true,
        type: "select",
        dicUrl: "/api/card/product/dropDown?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'productName', value: 'productId'}),
      },
      {
        label: "文字描述",
        prop: "description",
        align: "center",
        maxlength: 30,
        overHidden: true
      },
      {
        label: "状态",
        prop: "promotionStatus",
        align: "center",
        slot: true,
        addHide: true,
        editHide: true,
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        width: 150,
        addHide: true,
        editHide: true,
      },
      {
        label: "更新时间",
        prop: "editTime",
        align: "center",
        width: 150,
        addHide: true,
        editHide: true,
      },
    ]
  }

  getList () {
    this.tableLoading = true
    promotionQueryApi(this.queryParam).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 1MB!');
    }
    return isJPG && isLt2M;
  }

  rowSave (form: IAppletPromote, done: Function, loading: Function) {
    promotionCreateApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IAppletPromote, done: Function, loading: Function) {
    promotionModifyApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  statusChange (promotionId: string, promotionStatus: number) {
    this.tableLoading = true
    promotionModifyApi({promotionId, promotionStatus}).then(e => {
      if (e) {
        this.getList()
        this.$message.success("操作成功!")
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  openEdit (row: IAppletPromote, index: number) {
    promotionDetailApi(row.promotionId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
